









































































































































































































































@import '~@/assets/styles/helpers/_variables.scss';

.v-card { border-radius: 16px !important; }

.toolBar {
  background-color: $colorPrimary !important;
  border-radius: 16px !important;
  display: flex;
  justify-content: center;
  margin: 0px;
  color: white;

  &-text {
    font-weight: bold;
    text-transform: uppercase;
  }
}
